
import {Component, Prop, Vue} from 'vue-property-decorator';
import {DataTableHeader} from 'vuetify';
import {ITranslation} from '@/types/TranslationTypes';

@Component({
  name: 'TranslationsTable',
  components: {
    ConfirmationDialog: () => import('@/components/ConfirmationDialog.vue'),
  },
})
export default class TranslationsTable extends Vue {
  @Prop()
  data!: ITranslation[];

  @Prop()
  loading!: boolean;

  @Prop()
  search!: string;

  headers: DataTableHeader[] = [
    {
      text: 'SYSTEM_LABEL',
      value: 'label',
    },
    {
      text: 'SYSTEM_TRANSLATION',
      value: 'translation',
    },
    {
      text: 'SYSTEM_LABEL_TYPE',
      value: 'labelType',
    },
    {
      text: 'SYSTEM_OPTIONS',
      value: 'options',
      sortable: false,
      align: 'end',
    },
  ];

  labelToRemove: { id: number, label: string } | null = null;

  updateLabel(transaltion: ITranslation) {
    this.$emit('updateLabel', transaltion);
  }

  updateTranslation(transaltion: ITranslation) {
    this.$emit('updateTranslation', transaltion);
  }

  removeLabel(transaltion: ITranslation) {
    this.$emit('removeLabel', this.labelToRemove!.id);
    this.labelToRemove = null;
  }

  setRemoveLabel(transaltion: ITranslation) {
    this.labelToRemove = {
      id: transaltion.labelId,
      label: transaltion.label,
    };
  }
}
