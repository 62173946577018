
import {Component, Vue} from 'vue-property-decorator';
import Languages from '@/components/Translations/Languages.vue';
import TranslationsTable from '@/components/Translations/TranslationsTable.vue';
import {ITranslation} from '../types/TranslationTypes';
import TranslationService from '../services/TranslationService';
import {NotificationTypes, notify, resolveError} from '../utils/notifications';
import {translateApp} from '@/plugins/i18n';

@Component({
  name: 'Translations',
  components: {
    Languages,
    TranslationsTable,
    LabelForm: () => import('@/components/Translations/LabelForm.vue'),
  },
})
export default class Translations extends Vue {
  currentLangId: number = 0;

  translations: ITranslation[] = [];
  translationsLoading: boolean = false;

  search: string = '';

  isLabelFormOpen: boolean = false;

  setCurrentLangId(id: number) {
    this.currentLangId = id;
    this.fetchTranlations();
  }

  async fetchTranlations() {
    try {
      this.translationsLoading = true;
      this.translations = await TranslationService.getTranslations(this.currentLangId);
    } catch (e) {
      resolveError(e, 'fetch_data');
    } finally {
      this.translationsLoading = false;
    }
  }

  async updateTranslation(transaltion: ITranslation) {
    try {
      this.translationsLoading = true;
      await TranslationService.updateTranslation(this.currentLangId, transaltion);
      translateApp();
    } catch (e) {
      resolveError(e, 'update');
    } finally {
      this.fetchTranlations();
    }
  }

  async updateLabel(transaltion: ITranslation) {
    try {
      this.translationsLoading = true;
      await TranslationService.updateLabel(transaltion);
      translateApp();
    } catch (e) {
      resolveError(e, 'update');
    } finally {
      this.fetchTranlations();
    }
  }

  async removeLabel(id: number) {
    try {
      this.translationsLoading = true;
      await TranslationService.removeLabel(id);
      notify(NotificationTypes.success, this.$t('SYSTEM_REMOVED'));
      translateApp();
    } catch (e) {
      resolveError(e, 'remove');
    } finally {
      this.fetchTranlations();
    }
  }
}
